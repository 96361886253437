import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
// import { Row, Col } from "react-simple-flex-grid";
// import "react-simple-flex-grid/lib/main.css";
import { VStack, SimpleGrid, Box } from "@chakra-ui/react";

// styles
const pageStyles = {
    color: "#232129",
    padding: 48,
    fontFamily: "Eudoxus Sans, -apple-system, Roboto, sans-serif",
    fontWeight: 400,
    maxWidth: 800,
    margin: "0 auto"
};
const profileStyles = {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
};
const photoStyle = {
    borderRadius: "50%",
};
const titleStyles = {
    fontWeight: 600,
    marginTop: 8,
    marginBottom: 0,
};
const descriptionStyles = {
    marginTop: 8,
    marginBottom: 0,
};
const headingStyles = {
    fontWeight: 600,
    marginTop: 64,
    marginBottom: 0,
};
const linkListStyles = {
    marginTop: 16,
};
const linkLabelStyles = {
    display: "flex",
    alignItems: "center",
    background: "rgb(242, 242, 247)",
    padding: "8px 12px",
    borderRadius: 16,
    width: 280,
    boxSizing: "border-box" as "border-box",
};
const linkImageStyle = {
    borderRadius: "50%",
};
const linkTitleStyles = {
    fontWeight: 500,
    minWidth: 280 - 24,
    marginLeft: -30,
    textAlign: "center" as "center",
};
const sliderContainerStyles = {};
const sliderMainStyles = {};
const caseStyles = {
    boxSizing: "border-box" as "border-box",
    minWidth: 220,
    height: 260,
    padding: 8,
};
const cardStyles = {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between",
    boxSizing: "border-box" as "border-box",
    padding: 16,
    borderRadius: 20,
    boxShadow: "0px 0px 20px 0px rgba(28, 28, 30, 0.1)",
    width: "100%",
    height: "100%",
};
const cardImageStyles = {
    borderRadius: "16px",
    width: "100%",
    height: 80,
    background: "rgb(242, 242, 247)",
};
const cardTitleStyles = {
    marginTop: 8,
    marginBottom: 0,
    textAlign: "center",
};
const cardDescStyles = {
    marginTop: 6,
    marginBottom: 0,
};
const cardButtonStyles = {
    display: "flex",
    justifyContent: "center",
    borderRadius: 32,
    paddingTop: 6,
    paddingBottom: 6,
    background: "rgb(242, 242, 247)",
};
const cardButtonTextStyles = {
    fontWeight: 500,
    fontSize: 14,
};

const seo = {
    title: "Averie Wehr",
    description: "Artist Averie Wehr",
    url: "https://www.averiewehr.com",
};

const Work = ({ title, buttonUrlSM, buttonUrlLG, preview }) => {
    const image = getImage(preview);
    return (
        <div style={caseStyles}>
            <div style={cardStyles}>
                <div>
                    <GatsbyImage
                        image={image}
                        alt={title}
                        style={cardImageStyles}
                        className="image-radius-16px"
                    />
                    <h3 style={cardTitleStyles}>{title}</h3>
                </div>
                <div>
                    <a
                        href={buttonUrlSM}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div style={cardButtonStyles}>
                            <div style={cardButtonTextStyles}>Small Print</div>
                        </div>
                    </a>
                </div>
                <div>
                    <a
                        href={buttonUrlLG}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div style={cardButtonStyles}>
                            <div style={cardButtonTextStyles}>Large Print</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

const IndexPage = ({
    data: {
        allMarkdownRemark: { nodes: works },
    },
}) => {
    return (
        <main style={pageStyles}>
            <Helmet title={seo.title} htmlAttributes={{ lang: "en" }}>
                <meta name="description" content={seo.description} />
                <meta property="og:url" content={seo.url} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={seo.title} />
                <meta property="og:description" content={seo.description} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:creator" content={seo.title} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <link
                    rel="stylesheet"
                    href="https://stijndv.com/fonts/Eudoxus-Sans.css"
                />
                <style>
                    {`
            a {
              color: inherit;
              text-decoration: none;
            }
            div::-webkit-scrollbar {
              height: 8px;
            }
            div::-webkit-scrollbar-thumb {
              background-color: #d1d1d6;
              border-radius: 50px;
            }
            ::selection {
              background-color: #bbaaee;
            }
            .image-radius-50 img {
              border-radius: 50%;
              -o-border-radius: 50%;
              -moz-border-radius: 50%;
              -kthml-border-radius: 50%;
              -webkit-border-radius: 50%;
            }
            .image-radius-16px img {
              border-radius: 16px;
              -o-border-radius: 16px;
              -moz-border-radius: 16px;
              -kthml-border-radius: 16px;
              -webkit-border-radius: 16px;
            }
          `}
                </style>
            </Helmet>
            <div style={profileStyles}>
                <StaticImage
                    src="../images/av-profile.png"
                    alt="Photo"
                    placeholder="blurred"
                    layout="fixed"
                    width={120}
                    quality={100}
                    style={photoStyle}
                    className="image-radius-50"
                />
                <h1 style={titleStyles}>Averie 🌲 Wehr</h1>
                <p style={descriptionStyles}>
                    Artist for Days
                    <br />
                </p>
            </div>

            <h2 style={headingStyles}>Links</h2>
            <div style={linkListStyles}>
                <VStack spacing={8} align="center">
                    <a
                        href="https://instagram.com/averiewehr"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div style={linkLabelStyles}>
                            <StaticImage
                                src="../images/links/instagram.webp"
                                alt="Instagram"
                                placeholder="blurred"
                                layout="fixed"
                                width={30}
                                quality={100}
                                style={linkImageStyle}
                                className="image-radius-50"
                            />
                            <div style={linkTitleStyles}>Instagram</div>
                        </div>
                    </a>
                    <a href="mailto:hello@averiewehr.com">
                        <div style={linkLabelStyles}>
                            <StaticImage
                                src="../images/links/mail.webp"
                                alt="Mail me"
                                placeholder="blurred"
                                layout="fixed"
                                width={30}
                                quality={100}
                                style={linkImageStyle}
                                className="image-radius-50"
                            />
                            <div style={linkTitleStyles}>Mail me</div>
                        </div>
                    </a>
                    <a
                        href="https://buymeacoffee.com/averiewehr"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div style={linkLabelStyles}>
                            <StaticImage
                                src="../images/links/buymeacoffee.webp"
                                alt="Buy Me a Coffee"
                                placeholder="blurred"
                                layout="fixed"
                                width={30}
                                quality={100}
                                style={linkImageStyle}
                                className="image-radius-50"
                            />
                            <div style={linkTitleStyles}>Buy Me a Coffee</div>
                        </div>
                    </a>
                </VStack>
            </div>

            <h2 style={headingStyles}>Prints for Sale</h2>
            <SimpleGrid minChildWidth="250px" spacing="5px">
                {works.map((c, i) => {
                    return <Work key={i} {...c.frontmatter} />;
                })}
            </SimpleGrid>
        </main>
    );
};

export default IndexPage;

export const query = graphql`
    {
        allMarkdownRemark {
            nodes {
                frontmatter {
                    title
                    buttonUrlSM
                    buttonUrlLG
                    preview {
                        childImageSharp {
                            gatsbyImageData(
                                width: 200
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
            }
        }
    }
`;
